<template>
  <v-container class="registration-user-container px-3" id="container_gy">
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  //color: var(--v-primary-base);
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 14px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .img-wraper {
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
}
</style>

<script>
import categoryMixins from "~/mixins/category";
// import CustomService from "@/service/customService";
// import analyticsService from "@/commons/service/analyticsService";
export default {
  name: "RegistrationUser",
  data() {
    return {
      response: {},
      isSocialLogin: false,
      hasCard: true,
      step: 1,
      userInformationFields: [],
      userPrivacyFields: [],
      userInfoAndPrivacyFields: [],
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        person: {
          personInfos: []
        },
        contact: {}
      }
    };
  },
  props: {
    profile: { type: Object, required: false },
    regWithCard: { type: Boolean, required: false, default: false }
  },
  mixins: [categoryMixins],
  components: {},
  methods: {
    // async register() {
    //   this.response = {};
    //   let userDataValid = this.$refs.newCustomerDataForm.$refs.form.validate();
    //   let privacyDataValid = this.userData.companyId
    //     ? this.$refs.privacyOptionsForm.$refs.form.validate()
    //     : false;
    //   if (userDataValid && privacyDataValid) {
    //     await this.$refs.privacyOptionsForm.applyChages();
    //     let _this = this;
    //     CustomService.registerUser(this.userData, this.hasCard ? 1 : 0).then(
    //       data => {
    //         if (data.response.status == 0) {
    //           analyticsService.signUp(
    //             _this.hasCard ? "card" : "nocard",
    //             data.data.user.userId
    //           );
    //           if (window.fbq) window.fbq("track", "CompleteRegistration");
    //           this.$router.push({ name: "RegistrationCompleted" });
    //         } else {
    //           this.response = data.response;
    //         }
    //       }
    //     );
    //   } else {
    //     /**
    //      * If only user infotmation fields are present
    //      */
    //     if (this.$refs.newCustomerDataForm) {
    //       this.userInformationFields = this.$refs.newCustomerDataForm.$refs.form.$children.filter(
    //         field => {
    //           if (field.type === "button") {
    //             return false;
    //           }
    //           if (field.$el && field.$el.className.includes("menu")) {
    //             return false;
    //           }
    //           // eslint-disable-next-line no-prototype-builtins
    //           if (field.hasOwnProperty("valid")) {
    //             return !field.valid;
    //           }
    //           return true;
    //         }
    //       );
    //       /**
    //        * Focus on the first invalid field in user information fields list
    //        */
    //       this.scrollAndFocusOnFirstInvalidField(this.userInformationFields);
    //     }
    //     /**
    //      * If both user infotmation and privacy fields are present
    //      */
    //     if (this.$refs.newCustomerDataForm && this.$refs.privacyOptionsForm) {
    //       this.userPrivacyFields = this.$refs.privacyOptionsForm.$refs.form.$children.filter(
    //         field => !field.valid && field.type !== "button"
    //       );
    //     }
    //     /**
    //      * Focus on the first invalid field in user information and privacy fields list
    //      */
    //     if (this.userInformationFields && this.userPrivacyFields) {
    //       this.userInfoAndPrivacyFields = [
    //         ...this.userInformationFields,
    //         ...this.userPrivacyFields
    //       ];
    //       this.scrollAndFocusOnFirstInvalidField(this.userInfoAndPrivacyFields);
    //     }
    //   }
    // }
  },
  mounted() {
    let vm = this;
    const script = document.createElement("script");
    script.src = `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${process.env.VUE_APP_GIGYA_API_KEY}&lang=it`;

    script.async = true;
    script.onload = function() {
      // eslint-disable-next-line no-undef
      this.gigya = gigya;

      var _this = this;

      // function regEventHandler() {
      //   this.errorCode = null;
      //   this.respStatus = null;

      //   this.onEvento = function(eventObj) {
      //     console.log(eventObj);
      //   };

      //   this.onBeforeScreenLoad = function(eventObj) {
      //     console.log(eventObj);
      //   };
      // }

      // function delay(time) {
      //   return new Promise(resolve => setTimeout(resolve, time));
      // }

      if (vm.profile) {
        console.log("USER PASSED!: ", vm.profile);
      }

      function GigyaLoadFunctions() {
        _this.gigya.accounts.showScreenSet({
          containerID: "container_gy",
          screenSet: "Iperdrive-RegistrationLogin",
          startScreen: vm.$route.params.regWithCard
            ? "gigya-register-screen-existing-card"
            : "gigya-register-screen",
          onAfterScreenLoad: function(event) {
            console.log(event);
            // let form = document.getElementById("gigya-register-form");
            let emailAddress = document.querySelector(
              "#gigya-register-form input[type=text][name='email']"
            );
            if (emailAddress !== null) {
              emailAddress.setAttribute("value", vm.profile.data.value.e_mail);
            }
            let confirmEmail = document.querySelector(
              "#gigya-register-form input[type=text][name='profile.username']"
            );
            if (confirmEmail !== null) {
              confirmEmail.setAttribute("value", vm.profile.data.value.e_mail);
            }
            let lastName = document.querySelector(
              "#gigya-register-form input[type=text][name='profile.lastName']"
            );
            if (lastName !== null) {
              lastName.setAttribute(
                "value",
                vm.profile.data.value.person.lastName
              );
            }
            let firstName = document.querySelector(
              "#gigya-register-form input[type=text][name='profile.firstName']"
            );
            if (firstName !== null) {
              firstName.setAttribute(
                "value",
                vm.profile.data.value.person.firstName
              );
            }
            let phones = document.querySelector(
              "#gigya-register-form input[type=tel][name='profile.phones.number']"
            );
            if (phones !== null) {
              phones.setAttribute(
                "value",
                vm.profile.data.value.contact.cellPhone
              );
            }
            if (vm.profile.data.value.person.birthDate) {
              let date = vm
                .$dayjs(vm.profile.data.value.person.birthDate)
                .format("DD/MM/YYYY");

              let array = date.split("/");
              let day = document.querySelector(
                "#gigya-register-form select[name='profile.birthDay'] option[value=\"" +
                  array[0] +
                  '"]'
              );
              if (day != null) {
                day.setAttribute("selected", true);
              }
              // if (birthDay !== null) {
              //   birthDay.setAttribute("value", array[2]);
              // }
              let month = document.querySelector(
                "#gigya-register-form select[name='profile.birthMonth'] option[value=\"" +
                  array[1] +
                  '"]'
              );
              if (month != null) {
                month.setAttribute("selected", true);
              }
              // if (birthMonth !== null) {
              //   birthMonth.setAttribute("value", array[1]);
              // }
              let year = document.querySelector(
                "#gigya-register-form select[name='profile.birthYear'] option[value=\"" +
                  array[2] +
                  '"]'
              );
              if (year != null) {
                year.setAttribute("selected", true);
              }
            }
            // let warehouse = document.querySelector(
            //   "#gigya-register-form select[name='data.Punto_Vendita__c'] option[value=\"" +
            //     vm.profile.shippingAddress.addressName +
            //     '"]'
            // );
            // if (warehouse != null) {
            //   warehouse.setAttribute("selected", true);
            // }

            let city = document.querySelector(
              "#gigya-register-form input[type=text][name='profile.city']"
            );

            // address1: "Via",
            //   address2: "Bernardino Luini",
            //   province: "MI",

            if (city !== null) {
              city.setAttribute(
                "value",
                vm.profile.data.value.billingAddress.city
              );
            }
          },
          onError: function(eventObject) {
            if (eventObject.errorCode == 400009) {
              var customMessage =
                eventObject.response.info.response.validationErrors[0];
              var message_new = "";
              if (customMessage.fieldName == "data") {
                var messageTemp = customMessage.customMessage;
                var code_start = messageTemp.indexOf("SF Response Code :");
                var code_end = messageTemp.indexOf("SF Response Message :");
                if (code_start !== -1) {
                  // var code = messageTemp
                  //   .substring(code_start + 18, code_end - 2)
                  //   .trim();
                  message_new = messageTemp.substring(code_end + 21).trim();
                } else {
                  code_start = messageTemp.indexOf("Codice SF :");
                  code_end = messageTemp.indexOf("Messaggio SF :");
                  if (code_start !== -1) {
                    // var code = messageTemp
                    //   .substring(code_start + 11, code_end - 2)
                    //   .trim();
                    message_new = messageTemp.substring(code_end + 14).trim();
                  }
                }
                if (message_new != "") {
                  message_new = message_new.replaceAll(
                    /\[([^\]]+)\]/g,
                    '<a href="$1">$1</a>'
                  );
                  var message_div = document.getElementsByClassName(
                    "gigya-error-msg gigya-form-error-msg gigya-error-code-400009 gigya-error-msg-active"
                  );

                  setTimeout(() => {
                    Array.prototype.forEach.call(message_div, function(div) {
                      div.innerHTML = message_new;
                    });
                  }, 1000);
                }
              }
            }
          }
        });
      }
      GigyaLoadFunctions();
    };
    document.head.appendChild(script);
  }
};
</script>
